import React from 'react'
import ModalImage from 'react-modal-image';
import './qr-modal-image.css';

function QrModalImage({ src, className = '' }) {
  
  const handleQrClick = (e) => {
    if(e.target.nodeName !== 'IMG')
      return;

    let modalImage;

    modalImage = document.getElementById('react-modal-image-img');
    if(modalImage) {
      modalImage.style.transform = 'none';
      modalImage.outerHTML = `
        <div id="react-modal-image-container">
          ${modalImage.outerHTML}
          <span>Long press to see sharing options</span>
        </div>
      `;
      let modalContainer = document.getElementById('react-modal-image-container');
      modalContainer.onclick = e => e.stopPropagation();

    } else {
      let interval = setInterval(() => {
        modalImage = document.getElementById('react-modal-image-img');
        if(modalImage) {
          modalImage.style.transform = 'none';
          modalImage.outerHTML = `
            <div id="react-modal-image-container">
              ${modalImage.outerHTML}
              <span>Long press to see sharing options</span>
            </div>
          `;
          let modalContainer = document.getElementById('react-modal-image-container');
          modalContainer.onclick = e => e.stopPropagation();
          clearInterval(interval);
        }
      }, 50);
    }

  }

  return (
    <div onClick={handleQrClick}>
      <ModalImage
        hideZoom
        small={src}
        large={src}
        className={className}
      />
    </div>
  )
}

export default QrModalImage;