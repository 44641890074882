import React, { useEffect, useState } from "react";
import { Tab, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import axios from "../../MyAxios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EDIT_GROUP,
  GET_GROUP,
  GROUP_ADD_MEMBERS,
  GROUP_ADD_RESOURCES,
  LEAVE_GROUP,
  REQUEST_GROUP_JOIN,
  ROOT,
} from "../../constants/api";
import Layout from "../../Layout";
import back from "../../assets/img/back.svg";
import GroupStreams from "../../components/GroupStreams";
import GroupDescription from "../../components/GroupDescription";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import AddConnectionIcon from "@images/addMembers.svg";
import AddResourcesIcon from "@images/addResources.svg";
import EditGroupIcon from "@images/editGroup.svg";
import RemoveIcon from "@images/delete.svg";
import JoinGroupIcon from "@images/joinGroup.svg";
import GroupModal from "@components/GroupModal";
import MyAvatar from "@components/admin/MyAvatar";
import { toast } from "react-toastify";
import TabPane from "@components/TabPane";
import Resources from "./Resources";
import Loader from "@components/Loader";
import { trunc } from "@helpers";
import GroupTagManagement from "@pages/tags/GroupTagManagement";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#b1b1b1",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  active: {
    color: "#626262",
    borderBottomWidth: "4px",
    borderColor: theme.palette.primary.main,
  },
}));

function GroupInfo() {
  useLoggedIn();
  const navigate = useNavigate();

  const { userRecord, isLoggedIn } = useSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();

  const classes = useStyles();
  const { id } = useParams();
  const [active, setActive] = useState(1);
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);
  const [headerHeight, setHeaderHeight] = useState(0);

  const isAdminOrModerator = (user, group) => {
    return (
      user?.id === group?.admin?.id ||
      group?.moderators?.some((m) => m?.id === user?.id)
    );
  };

  const isAdmin = () => userRecord?.id === group?.admin?.id;

  const options = [];
  const adminOrModerator = isAdminOrModerator(userRecord, group);

  const handleJoinGroup = () => {
    axios
      .post(REQUEST_GROUP_JOIN, { fromGroup: group?.id })
      .then((res) => {
        toast.success("A join request has been sent successfully");
      })
      .catch(() => {});
  }

  if (adminOrModerator) {
    if(isAdmin() || group?.moderatorAccessLevel?.canAddMembers)
      options.push({
        name: "Add Members",
        img: AddConnectionIcon,
        action: () => {
          navigate(GROUP_ADD_MEMBERS, { state: { groupId: group?.id } });
        },
      });

    options.push(
      {
        name: "Add Resources",
        img: AddResourcesIcon,
        action: () => {
          navigate(GROUP_ADD_RESOURCES, { state: { groupId: group?.id } });
        },
      },
      {
        name: "Invite Through Email",
        img: AddConnectionIcon,
        action: () => {
          navigate(`/group/email-invites/${group?.id}`, { state: { groupName: group?.name } })
        },
      },
      // {
      //   name: "Delete",
      //   img: RemoveIcon,
      // },
    );

    if(isAdmin() || group?.moderatorAccessLevel?.canEditGroup)
      options.push({
        name: "Edit Group",
        img: EditGroupIcon,
        action: () => {
          navigate(EDIT_GROUP, { state: { group: group } });
        },
      });

  } else if (group?.members?.map((x) => x.id).includes(userRecord?.id)) {
    options.push({
      name: "Leave Group",
      img: RemoveIcon,
      action: () => {
        setOpen(false);
        axios
          .post(LEAVE_GROUP, { groupId: group?.id })
          .then((res) => {
            toast.success("Left Group");
          })
          .catch(() => {});
      },
    });
  } else {
    options.push({
      name: "Join Group",
      img: JoinGroupIcon,
      action: () => {
        setOpen(false);
        handleJoinGroup();
      },
    });
  }
  const incPage = () => {
    setPage(page + 1);
  };

  const GetGroup = async (onlyGroup = false, noLoading = false) => {
    try {
      if(!noLoading)
        setLoading(true);

      await axios.get(`${GET_GROUP}/${id}/${page}`).then((res) => {
        setGroup(res.data.group);
        setMembers((members) => [...members, ...res.data.group.members]);

        if(!onlyGroup) {
          setTotalMembers(res.data.totalMembers);
          setTotalPages(res.data.totalPages);
        }
      });

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetGroup();
  }, [page, id]);

  useEffect(() => {
    // If visited page through QR code and group not joined
    if(
      group
      && userRecord?.allGroups
      && searchParams.get('trigger') === 'join'
      && !Boolean(userRecord?.allGroups.find(g => g.id === group.id))
    ) {
      handleJoinGroup();
    }
  }, [group, userRecord, searchParams])

  useEffect(() => {
    if(!isLoggedIn) {
      let redirectUrl = `/group/info/${id}`;

      if(searchParams.get('trigger') === 'join')
        redirectUrl += '?trigger=join'

      localStorage.setItem('loginRedirect', redirectUrl);
    }
  }, [isLoggedIn]);

  const getGroupName = () => {
    if(!group)
      return '';

    let truncName = trunc(group?.name, 20);

    if(group?.disabledAt > 0)
      return `${truncName} (Disabled)`;

    return truncName;
  }

  const getCommunityName = (com) => {
    if(!com)
      return '';

    let truncName = trunc(com?.name, 20);

    if(com?.disabledAt > 0)
      return `${truncName} (Disabled)`;

    return truncName;
  }

  const tabs = ["Streams", "Resources", "Tags", "Group Info"];

  // if(isAdmin())
  //   tabs.splice(2, 0, "Tags");

  if (loading)
    return <Loader />;

  return (
    <Layout>
      <div className="w-full bg-white">
        <div
          ref={(ref) => {
            if(ref)
              setHeaderHeight(ref.clientHeight)
          }}
          className="fixed w-full z-50"
        >
          <div
            className={clsx(
              classes.colorGreen,
              "shadow-black-100 flex justify-between items-center py-2 shadow-md md:w-1/2 px-2 overflow-y-auto"
            )}
          >
            <div className="flex items-center self-start">
              <img
                onClick={() => navigate(-1)}
                alt="Back"
                className="h-4 w-4 mr-4 cursor-pointer"
                src={back}
              />
              <MyAvatar
                src={group?.icon?.avatarUrl}
                name={
                  <Typography className="inline font-bold">
                    {getGroupName(group?.name)}{' '}
                    <Typography className="text-xs inline">
                      ({getCommunityName(group?.community)})
                    </Typography>
                  </Typography>
                }
                subtext={`${totalMembers} Members`}
                textProps={{ className: "font-semibold" }}
              />
            </div>

            {(options.length > 0 && group?.disabledAt === 0 && group?.community?.disabledAt === 0) && (
              <div>
                <MoreVertIcon
                  className="cursor-pointer"
                  onClick={() => setOpen(true)}
                />
              </div>
            )}
          </div>
        </div>

        <div style={{ marginTop: headerHeight + 4 }}>
          <TabPane tabs={tabs}>
            <div>
              <GroupStreams group={group} />
            </div>
            <div>
              <Resources
                groupId={group?.id}
                allowEdit={adminOrModerator}
                readOnly={group?.disabledAt > 0 || group?.community?.disabledAt > 0}
              />
            </div>
            <div>
              <GroupTagManagement
                group={group}
                getGroup={GetGroup}
              />
            </div>
            <div>
              <GroupDescription
                group={group}
                members={members}
                setGroup={setGroup}
                setMembers={setMembers}
                incPage={incPage}
                page={page}
                totalPages={totalPages}
              />
            </div>
          </TabPane>
        </div>
      </div>

      {/* {active === 1 ? (
        <div>
          <GroupStreams group={group} />
        </div>
      ) : (
        <div>
          <GroupDescription group={group} setGroup={setGroup} />
        </div>
      )} */}

      <GroupModal open={open} setOpen={setOpen} options={options} />
    </Layout>
  );
}

export default GroupInfo;
