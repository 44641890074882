import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import { TextField, Typography } from "@mui/material";
import Loader from "@components/Loader";
import ProfileTag from "./ProfileTag";
import { refreshUserRecord } from "@helpers";

function ProfileRestrictedTags() {
  useLoggedIn();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRecord } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsToShow, setTagsToShow] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [search, setSearch] = useState("");

  const fetchData = () => {
    setLoading(true);
    axios.get("/tags")
      .then((res) => {
        let flattenedTags = [];

        Object.values(res.data).map(tagList => flattenedTags.push(...tagList));

        let filteredFlattenedTags = [];

        flattenedTags.forEach(tag => {
          if(!Boolean(filteredFlattenedTags.find(t => t.subcategory == tag.subcategory)) && !userRecord?.profileTags?.includes(tag.subcategory))
            filteredFlattenedTags.push(tag);
        });

        setTags(filteredFlattenedTags);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  const onTagClick = (tag) => {
    if(Boolean(userRecord?.restrictedTags?.find(t => t == tag.subcategory)))
      return toast.error('You have already selected this tag');

    setSelectionLoading(true);
    axios.get('/restrict-profile-tag', { params: { tag } })
      .then((res) => {
        refreshUserRecord(dispatch, () => setSelectionLoading(false));
      })
      .catch(() => setSelectionLoading(false))
  }

  const onTagRemove = (tag) => {
    if(!Boolean(userRecord?.restrictedTags?.find(t => t == tag.subcategory)))
      return toast.error('This tag is not selected');

    setSelectionLoading(true);
    axios.get('/unrestrict-profile-tag', { params: { tag: tag.subcategory } })
      .then((res) => {
        // toast.success(res.data.message);
        refreshUserRecord(dispatch, () => setSelectionLoading(false));
      })
      .catch(() => setSelectionLoading(false))
  }

  useEffect(() => {
    if(tags) {
      let tempTags = [ ...tags ];

      if(search) {
        let searchLower = search.toLowerCase();
        tempTags = tempTags.filter(tag => tag.subcategory.toLowerCase()?.includes( searchLower ));
      }

      setTagsToShow(tempTags);
    }
  }, [search, tags]);

  useEffect(() => {
    if(userRecord?.id && tags.length === 0)
      fetchData();
  }, [userRecord]);

  if (loading)
    return <Loader />;

  return (
    <div className="mx-auto">
      <TextField
        sx={{ "& fieldset": { border: "none" } }}
        className="mt-4 mb-6"
        type="text"
        size="small"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => e.key === "Enter" && fetchData()}
        placeholder="Search Tags"
        InputProps={{
          disableUnderline: true,
          sx: {
            borderRadius: 2,
            padding: 0.2,
            backgroundColor: "rgb(98, 98, 98, 0.1)",
            border: "none",
          },
        }}
      />
      <div className="px-4 pt-2">
        <div className="flex gap-2 flex-wrap">
          {tags?.length === 0 && (
            <Typography>
              No tags in your communities
            </Typography>
          )}
          {tagsToShow?.map((tag) => {
            let tagIsSelected = Boolean(userRecord?.restrictedTags?.find(t => t == tag.subcategory));

            return (
              <ProfileTag
                name={tag.subcategory}
                disabled={selectionLoading}
                onClick={() => onTagClick(tag.subcategory)}
                onRemove={tagIsSelected ? () => onTagRemove(tag) : null}
                selected={tagIsSelected}
                highlightColor='#ffc1c1'
                removalMuiColor='error'
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProfileRestrictedTags;