import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_GROUP,
  GROUP_MANAGEMENT,
  GROUP_SELECT_COMMUNITY,
  PROFILE,
  STREAM_MANAGEMENT,
} from "../constants/api";
import { Link, useNavigate } from "react-router-dom";
import { setIsLoggedIn } from "../redux/userSlice";
import MyAvatar from "./admin/MyAvatar";
import { setClickedPostId } from "@redux/prevPostsSlice";
import { resetPost } from "@redux/postSlice";

export default function TemporaryDrawer({ open, handleClose }) {
  const { userRecord } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    if (open) {
      handleClose();
    }
  };

  const data = [
    {
      id: 1,
      text: "My Profile",
      link: PROFILE,
    },
    // {
    //   id: 2,
    //   text: "Connections Management",
    //   link: `${PROFILE}?activeTab=${2}`,
    // },
    {
      id: 2,
      text: "Stream Management",
      link: STREAM_MANAGEMENT,
    },
    {
      id: 3,
      text: "Group Management",
      link: GROUP_MANAGEMENT,
    },
    {
      id: 4,
      text: "Tag Management",
      link: "/profile/tag-management",
    },
    // {
    //   id: 4,
    //   text: "Create Group",
    //   link:
    //     userRecord?.role === "admin" ? GROUP_SELECT_COMMUNITY : CREATE_GROUP,
    // },
    {
      id: 5,
      text: "Messages",
      link: "/chats",
    },
    {
      id: 6,
      text: "Saved Content",
      link: "/save",
    },
    // {
    //   id: 7,
    //   text: "Contact Us",
    //   link: "/contact",
    // },
    {
      id: 8,
      text: "Blocked Content",
      link: "/blocked",
    },
    {
      id: 7,
      text: "Contact Us",
      link: "/contact",
    },
    {
      id: 11,
      text: "Invite Friends",
      link: "/invite-friends",
    },
    // {
    //   id: 9,
    //   text: "Terms and Conditions",
    //   link: "/user-agreement",
    // },
    // {
    //   id: 10,
    //   text: "Privacy Policy",
    //   link: "/privacy-policy",
    // },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setIsLoggedIn(false));
    dispatch(resetPost()); // Reset any data in create post
    navigate("/login");
  };

  const handleInvite = () => {
    navigator.share({
      title: "Serkohls",
      text: `You should check out Serkohls! It's all about connecting with like-minded people and building community. Their slogan is "Your values. Your people. Your community. Your Serkohls," and I think it really sums up the vibe of the site. I'd love to connect with you there!\nPlease use this link to join: https://serkohls.com`,
    });
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className=" flex flex-row items-center py-4 mb-2 pl-5">
        <MyAvatar
          className="h-10 w-10 rounded-full"
          src={userRecord?.media?.avatarUrl}
          textProps={{ className: "font-bold" }}
          name={userRecord?.userName}
          subtext={userRecord?.firstName + " " + userRecord?.lastName}
        />
      </div>
      <List>
        {data.map((item, key) => (
          <Link
            key={key}
            to={item.link}
            onClick={() => dispatch(setClickedPostId(""))}
          >
            <ListItem key={item.id} disablePadding>
              <ListItemButton>
                <Typography className="text-sm">{item.text}</Typography>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}

        {/* <Link onClick={handleInvite}>
          <ListItem disablePadding>
            <ListItemButton>
              <Typography className="text-sm">Invite Friends</Typography>
            </ListItemButton>
          </ListItem>
        </Link> */}

        <Link onClick={handleLogout}>
          <ListItem disablePadding>
            <ListItemButton>
              <Typography className="text-sm">Log Out</Typography>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          className="mt-20"
          open={open}
          onClose={toggleDrawer}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
