import { useEffect, useState } from "react";
import Layout from "../../Layout";
import Title from "../../components/Title";
import useLoggedIn from "../../hooks/useLoggedIn";
import axios from "@MyAxios";
import {
  APPROVE_CONNECTION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GROUP_ADD_MEMBERS,
  READ_NOTIFICATION,
} from "@constants/api";
import MyAvatar from "@components/admin/MyAvatar";
import { Button, IconButton, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { MoreVert } from "@mui/icons-material";
import GroupModal from "@components/GroupModal";
import BlockIcon from "@images/block.svg";
import DeleteIcon from "@images/delete.svg";
import { useNavigate } from "react-router-dom";
import { calTime } from "@helpers";
import { useDispatch, useSelector } from "react-redux";
import { setExpandedNotification } from "@redux/notificationsSlice";

function NotificationItem({
  notification,
  fromUser,
  deleteNotification,
  fromGroup,
  fromCommunity,
  notificationGroup,
}) {
  const navigate = useNavigate();
  const getNotificationMessage = () => {
    switch (notification.type) {
      case "Connection":
        return `${fromUser?.firstName} ${fromUser?.lastName} sent you a connection request`;
      case "ConnectionApproval":
        return `${fromUser?.firstName} ${fromUser?.lastName} accepted your connection request`;
      case "JoinRequest":
        return `${fromUser?.firstName} ${fromUser?.lastName} sent you a group join request`;
      case "JoinRequestApproval":
        return `You have been added to ${fromGroup?.name} group by its admin`;
      case "Reply":
        return `${fromUser?.firstName} ${fromUser?.lastName} replied to your comment`;
      case "Comment":
        return `${fromUser?.firstName} ${fromUser?.lastName} commented on your post`;
      case "GroupPost":
        return `${fromGroup?.name} has a new post for you`;
      case "Message":
        return `${fromUser?.firstName} ${fromUser?.lastName} sent you a message`;
      case "PostMention":
        return `${fromUser?.firstName} ${fromUser?.lastName} mentioned you in a post`;
      case "PostActivity":
        return `There's new activity on a post you're following`;
      case "GroupInvitation":
        return `You are invited to join ${fromGroup?.name}`;
      case "CommunityInvitation":
        return `You are invited to join ${fromCommunity?.name} community`;
      case "AdminPostDelete":
        return `A post connected to you was deleted by superadmin: "${notification?.message}"`;
      default:
        return "New Notification";
    }
  };
  const getNotificationButtons = () => {
    switch (notification.type) {
      case "Connection":
        return [
          {
            children: "Approve",
            onClick: () => {
              axios
                .post(APPROVE_CONNECTION, { connectionId: fromUser.id })
                .then(() => {
                  deleteNotification(notification.id);
                  toast.success("Connection request approved");
                  return axios.post("/notification/approve/connection", {
                    connectionId: fromUser.id,
                  });
                })
                .catch(() => {});
            },
          },
          {
            children: "Reject",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "ConnectionApproval":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/${fromUser?.userName}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "JoinRequest":
        return [
          {
            children: "Approve",
            onClick: () => {
              axios
                .post(GROUP_ADD_MEMBERS, {
                  groupId: fromGroup?.id,
                  memberIds: [fromUser?.id],
                })
                .then(() => {
                  deleteNotification(notification.id);
                  toast.success("Join request approved");
                  return axios.post("/notification/approve/group-join", {
                    fromGroup: fromGroup?.id,
                    forUser: fromUser?.id,
                  });
                })
                .catch(() => {});
            },
          },
          {
            children: "Reject",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "GroupInvitation":
        return [
          {
            children: "Join",
            onClick: () => {
              axios
                .post("/notification/accept/group-invitation", {
                  id: notificationGroup.id,
                })
                .then(() => {
                  deleteNotification(notification.id);
                  toast.success("Invitation accepted");
                })
                .catch(() => {});
            },
          },
          {
            children: "Reject",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "JoinRequestApproval":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/group/info/${fromGroup?.id}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "GroupPost":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/post/${notification?.post}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "PostActivity":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/post/${notification?.post}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "Reply":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/post/${notification.post}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "Message":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/user/chat/${fromUser?.id}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];
      case "Comment":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/post/${notification.post}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "PostMention":
        return [
          {
            children: "View",
            onClick: () => {
              navigate(`/post/${notification.post}`);
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      case "CommunityInvitation":
        return [
          {
            children: "Join",
            onClick: () => {
              axios
                .post("/notification/accept/community-invitation", { id: notificationGroup.id })
                .then(() => {
                  deleteNotification(notification.id);
                  toast.success("Community invitation accepted");
                })
                .catch(() => {});
            },
          },
          {
            children: "Delete",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];

      default:
        return [];
    }
  };
  return (
    <div className="rounded-md p-3 bg-gray-200 mt-4">
      <div className="mb-2">{getNotificationMessage()}</div>
      <div>
        {getNotificationButtons().map((button, key) => (
          <Button
            key={key}
            sx={{ textTransform: "none", fontWeight: "bold" }}
            className="text-sm"
            {...button}
          />
        ))}
      </div>
      <div className="flex">
        <div className="ml-auto text-xs">{calTime(notification.createdAt)}</div>
      </div>
    </div>
  );
}

function NotificationsDropDown({
  notificationGroup,
  markAsRead,
  deleteNotificationGroup,
  blockNotificationGroup,
  setNotifications,
}) {
  const dispatch = useDispatch();

  const { expandedNotification } = useSelector(state => state.notifications)

  const [open, setOpen] = useState(false);

  const fromUser = notificationGroup?.fromUser;
  const fromGroup = notificationGroup?.fromGroup;
  const fromCommunity = notificationGroup?.fromCommunity;
  const fromAdmin = Boolean(notificationGroup?.fromAdmin);
  const notifications = notificationGroup?.notifications;

  const unreadCount = notifications?.filter((n) => !n.isRead).length;

  const [openOptions, setOpenOptions] = useState(false);

  const subtext =
    unreadCount > 0 ? (
      <Typography
        color={"primary.main"}
        className="text-sm"
      >{`${unreadCount} unread notifications`}</Typography>
    ) : (
      `${notifications.length} notifications`
    );

  const deleteNotification = (notificationId) => {
    axios.post(DELETE_NOTIFICATION, { id: notificationId });
    const newNotifications = notifications.filter(
      (n) => n.id !== notificationId
    );
    setNotifications(newNotifications);
  };

  const options = [
    {
      name: "Block",
      img: BlockIcon,
      action: () => {
        blockNotificationGroup(notificationGroup.id);
        setOpenOptions(false);
      },
    },
    {
      name: "Delete",
      img: DeleteIcon,
      action: () => {
        deleteNotificationGroup(notificationGroup.id);
        setOpenOptions(false);
      },
    },
  ];

  useEffect(() => {
    if(expandedNotification === notificationGroup?.id)
      setOpen(true);
  })

  if (notifications.length === 0)
    return null;

  return (
    <div>
      <div
        onClick={() => {
          setOpen(!open);
          markAsRead();
          dispatch(setExpandedNotification(!open ? notificationGroup?.id : null))
        }}
        className="border-b-2 border-gray-100 p-4 cursor-pointer"
      >
        <div className="flex justify-between">
          {fromUser && (
            <MyAvatar
              name={fromUser?.userName}
              subtext={subtext}
              src={fromUser?.media?.avatarUrl}
            />
          )}
          {fromGroup && (
            <MyAvatar
              name={fromGroup?.name}
              subtext={subtext}
              src={fromGroup?.icon?.avatarUrl}
            />
          )}
          {fromCommunity && (
            <MyAvatar
              name={fromCommunity?.name}
              subtext={subtext}
              src={fromCommunity?.icon?.avatarUrl}
            />
          )}
          {fromAdmin && (
            <MyAvatar
              name='Superadmin'
              subtext={subtext}
            />
          )}
          <div className="self-center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpenOptions(!openOptions);
              }}
            >
              <MoreVert />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="px-4">
        {open &&
          notifications.map((n, key) => (
            <NotificationItem
              notificationGroup={notificationGroup}
              key={key}
              notification={n}
              fromUser={fromUser || n.fromUser}
              deleteNotification={deleteNotification}
              fromGroup={fromGroup}
              fromCommunity={fromCommunity}
            />
          ))}
      </div>
      <GroupModal
        options={options}
        open={openOptions}
        setOpen={setOpenOptions}
      />
    </div>
  );
}

export default function Notifications() {
  useLoggedIn();
  const [groupedNotifications, setGroupedNotifications] = useState([]);
  const deleteNotificationGroup = (id) => {
    axios
      .post(`/notification/group/delete/${id}`)
      .then(() => {
        toast.success("Notification group deleted");
        setGroupedNotifications(
          groupedNotifications.filter((g) => g.id !== id)
        );
      })
      .catch(() => {});
  };

  const blockNotificationGroup = (id) => {
    axios
      .post(`/notification/group/block/${id}`)
      .then(() => {
        toast.success("You'll no longer receive these notifications");
        setGroupedNotifications(
          groupedNotifications.filter((g) => g.id !== id)
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    axios
      .get(GET_NOTIFICATIONS)
      .then((res) => {
        setGroupedNotifications(res.data);
      })
      .catch(() => {});
  }, []);

  const markAsRead = (groupId, key) => {
    axios.post(READ_NOTIFICATION, {
      id: groupId,
    });
    groupedNotifications[key].notifications.map((n) => (n.isRead = true));
    setGroupedNotifications(groupedNotifications);
  };
  const setNotifications = (newNotifications, key) => {
    const newGroupedNotifications = [...groupedNotifications];
    newGroupedNotifications[key].notifications = newNotifications;
    setGroupedNotifications(newGroupedNotifications);
  };
  return (
    <Layout>
      <Title back={"/"}>Notifications</Title>
      {groupedNotifications.map((group, key) => (
        <NotificationsDropDown
          key={key}
          notificationGroup={group}
          markAsRead={() => markAsRead(group.id, key)}
          setNotifications={(newNotifications) =>
            setNotifications(newNotifications, key)
          }
          deleteNotificationGroup={deleteNotificationGroup}
          blockNotificationGroup={blockNotificationGroup}
        />
      ))}
    </Layout>
  );
}
