import { Typography } from "@mui/material";
import React from "react";
import SearchItem from "./SearchItem.";
import MyAvatar from "@components/admin/MyAvatar";
import { useNavigate } from "react-router-dom";
import ShowMore from "./ShowMore";

export default function UsersList({ users, page, totalPages, onShowMore }) {
  const navigate = useNavigate();
  const onProfileClick = (userName) => {
    navigate(`/${userName}`);
  };

  return (
    <div>
      {users?.length === 0 && (
        <Typography className="mt-2 text-center py-8">
          No people found
        </Typography>
      )}
      {users?.length > 0 && (
        <div className="pt-2 pb-8">
          {users?.map((member, key) => (
            <SearchItem key={key} onClick={() => onProfileClick(member.userName)}>
              <MyAvatar
                src={member.media?.avatarUrl}
                subtext={`${member.firstName} ${member.lastName}`}
                textProps={{ className: "font-medium" }}
                name={member.userName}
              />
            </SearchItem>
          ))}
          <ShowMore page={page} totalPages={totalPages} onClick={onShowMore} />
        </div>
      )}
    </div>
  );
}
