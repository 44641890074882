import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect } from "react";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_PROFILE, PROFILE } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../Layout";
import Title from "../../components/Title";
import useLoggedIn from "../../hooks/useLoggedIn";
import { refreshUserRecord } from "@helpers";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const EditProfile = (res) => {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const { userRecord } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("userName", userRecord.userName);
    setValue("id", userRecord.id);
    setValue("emailAddress", userRecord.email || "");
    setValue("phoneNumber", userRecord.phoneNumber || "");
    setValue("firstName", userRecord.firstName || "");
    setValue("lastName", userRecord.lastName || "");
    setValue("aliasOne", userRecord.aliasOne || "");
    setValue("aliasTwo", userRecord.aliasTwo || "");
    setValue("about", userRecord.about || "");
  }, [userRecord, setValue]);

  // Form submission handler
  const onSubmit = async (data) => {
    try {
      await axios.post(EDIT_PROFILE, data).then((res) => {
        refreshUserRecord(dispatch);
        navigation(PROFILE);
      });
    } catch (error) {}
  };

  const validatePhoneNumber = (value) => {
    if (value === "") return true;
    const phoneNumberDigits = value.replace(/\D/g, ""); // Remove non-digits
    if (!/^\d+$/.test(phoneNumberDigits)) {
      // Check if contains only digits
      return "Phone number must contain only digits";
    }
    if (phoneNumberDigits.length !== 10) {
      // Check if length is 10
      return "Phone number must be 10 digits";
    }
    return true; // Validation passed
  };

  return (
    <Layout>
      <Title back={PROFILE}>Edit Profile</Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="mx-10 flex flex-col gap-2"
      >
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>First Name</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="First Name"
            type="text"
            {...register("firstName", {
              required: "First Name is required",
              minLength: {
                value: 3,
                message: "First Name must be at least 3 characters",
              },
            })}
          />
          {errors.firstName && (
            <p className="text-xs italic text-red-500">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Last Name</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Last Name"
            type="text"
            {...register("lastName", {
              required: "Last Name is required",
              minLength: {
                value: 3,
                message: "Last Name must be at least 3 characters",
              },
            })}
          />
          {errors.lastName && (
            <p className="text-xs italic text-red-500">
              {errors.lastName.message}
            </p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Phone Number</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="(XXX) XXX-XXXX"
            type="text"
            {...register("phoneNumber", {
              validate: validatePhoneNumber,
              required: false,
            })}
          />
          {errors.phoneNumber && (
            <p className="text-xs italic text-red-500">
              {errors.phoneNumber.message}
            </p>
          )}
        </div>

        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>About Me</small>
          </label>
          <textarea
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="About"
            {...register("about")}
          />
        </div>

        <div className="input-wrapper">
          <Button>Save your profile</Button>
        </div>
      </form>
    </Layout>
  );
};

export default EditProfile;
