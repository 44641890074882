import axios from "@MyAxios";
import Loader from "@components/Loader";
import { GET_ALL_USERS } from "@constants/api";
import useLoggedIn from "@hooks/useLoggedIn";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function RedirectToProfile() {
  useLoggedIn();

  const navigate = useNavigate();
  const { userName } = useParams();

  const { isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    if(isLoggedIn)
      navigate(`/${userName}`, { replace: true });
    else
      localStorage.setItem('loginRedirect', `/${userName}`)
  }, [navigate, userName]);

  return <Loader />;
}
