import React from 'react'
import MyAvatar from './admin/MyAvatar';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function MemberListingItem({ member, setSelectedMember }) {
  const { userRecord } = useSelector((state) => state.user);

  return (
    <div
      key={member?.id}
      className="flex mt-2 items-center mr-5 justify-between"
    >
      <div className="flex">
        <Link to={`/${member?.userName}`}>
          <MyAvatar
            textProps={{ className: "font-bold" }}
            name={member?.userName}
            className="z-0 w-14 h-14 mr-3"
            subtext={member?.firstName + " " + member?.lastName}
            src={member?.media?.avatarUrl}
          />
        </Link>
        {member.isModerator && (
          <div className="flex flex-col justify-center">
            <div className="flex items-center">
              <Typography
                className="ml-4 p-2 rounded-lg bg-[#e5f8f1]"
                color="primary.main"
              >
                Moderator
              </Typography>
            </div>
          </div>
        )}
        {member.isAdmin && (
          <div className="flex flex-col justify-center">
            <Typography
              className="ml-4 p-2 rounded-lg bg-[#e5f8f1]"
              color="primary.main"
            >
              Community Admin
            </Typography>
          </div>
        )}
      </div>
      {(userRecord.id !== member.id && !member.isAdmin) && (
        <MoreHorizIcon
          onClick={() => setSelectedMember(member)}
          className="cursor-pointer"
          fontSize="large"
        />
      )}
    </div>
  )
}

export default MemberListingItem;