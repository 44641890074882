import React, { useEffect, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import addConnection from "@images/addConnection.svg";
import message from "@images/message.svg";
import moderator from "@images/moderator.svg";
import remove from "@images/remove.svg";
import report from "@images/reportGreen.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GroupModal from "./GroupModal";
import resources from "@images/resources.svg";
import { useNavigate } from "react-router-dom";
import {
  GET_USER_CHAT,
  GROUP_ADD_MODERATOR,
  GROUP_REMOVE_MEMBER,
  GROUP_RESOURCES,
  REPORT_FORM,
} from "@constants/api";
import { toast } from "react-toastify";
import axios from "@MyAxios";
import MyAvatar from "./admin/MyAvatar";
import Loader from "./Loader";
import ResourcesPreview from "./ResourcesPreview";
import { useSelector } from "react-redux";
import ShowMore from "./ShowMore";
import QrModalImage from "./qr-modal-image/QrModalImage";
import QRCode from 'qrcode'

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#b1b1b1",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  active: {
    color: "#626262",
    borderBottomWidth: "4px",
    borderColor: theme.palette.primary.main,
  },
}));

function GroupDescription({
  group,
  setGroup,
  incPage,
  page,
  totalPages,
  members,
  setMembers,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { userRecord } = useSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [qrCodeDataUri, setQrCodeDataUri] = useState();

  // const [mediaResourcePreview, setMediaResourcePreview] = useState([]);
  // const Resources = () => {
  //   navigate(GROUP_RESOURCES, { state: { groupId: group?.id } });
  // };
  // useEffect(() => {
  //   if (!group?.id) return;
  // setLoading(true);
  // axios
  //   .get(`${GROUP_RESOURCES}/${group?.id}?limit=${4}`)
  // .then((res) => {
  //   // setMediaResourcePreview(res.data.mediaResources);
  // })
  // .catch(() => {})
  // .finally(() => {
  //   setLoading(false);
  // });
  // }, [group]);

  const doesUserHasPermission = (user, group) => {
    return (
      user?.id === group?.admin?.id ||
      group?.moderators?.some((m) => m?.id === user?.id)
    );
  };

  const isAdmin = () => userRecord?.id === group?.admin?.id;
  const isModeratorAndCanRemoveModerator = () => group?.moderators?.some((m) => m?.id === userRecord?.id) && group?.moderatorAccessLevel?.canRemoveGroupModerators;
  const isModeratorAndCanAddModerator = () => group?.moderators?.some((m) => m?.id === userRecord?.id) && group?.moderatorAccessLevel?.canAddGroupModerators;
  const groupIsFromDisabledCommunity = group?.community?.disabledAt > 0;
  const groupIsDisabled = group?.disabledAt > 0;

  const options = [
    // {
    //   id: 1,
    //   name: "Connect",
    //   hide: userRecord?.id === selectedUser?.id,
    //   img: addConnection,
    // },
    {
      id: 2,
      name: "Message",
      img: message,
      action: () => {
        console.log(selectedUser);
        navigate(`${GET_USER_CHAT}/${selectedUser?.id}`);
      },
    },
    {
      id: 3,
      name: "Make Moderator",
      img: moderator,
      hide:
        selectedUser?.isModerator
        || groupIsDisabled
        || groupIsFromDisabledCommunity
        || (!isAdmin() && !isModeratorAndCanAddModerator()),
      action: async () => {
        try {
          await axios.post(`${GROUP_ADD_MODERATOR}`, {
            groupId: group?.id,
            memberId: selectedUser?.id,
          });
          setGroup({
            ...group,
            members: group?.members.map((x) => {
              if (x.id === selectedUser?.id) {
                x.isModerator = true;
              }
              return x;
            }),
          });
          toast.success("Added Successfully!");
          setOpen(false);
        } catch (error) {}
      },
    },
    {
      id: 4,
      name: "Remove",
      hide:
        userRecord?.id === selectedUser?.id
        || groupIsDisabled
        || groupIsFromDisabledCommunity
        || !doesUserHasPermission(userRecord, group),
      img: remove,
      action: async () => {
        try {
          await axios.post(`${GROUP_REMOVE_MEMBER}`, {
            groupId: group?.id,
            memberId: selectedUser?.id,
          });

          setGroup({
            ...group,
            members: group?.members.filter((x) => x.id !== selectedUser?.id),
            moderators: group?.moderators.filter(
              (x) => x.id !== selectedUser?.id
            ),
          });
          setMembers(members.filter((x) => x.id !== selectedUser?.id));
          setOpen(false);
          toast.success("Removed Successfully!");
        } catch (error) {}
      },
    },
    {
      id: 5,
      name: "Remove Moderator",
      hide:
        userRecord?.id === selectedUser?.id
        || groupIsDisabled
        || groupIsFromDisabledCommunity
        || (!isAdmin() && !isModeratorAndCanRemoveModerator()),
      img: remove,
      action: async () => {
        try {
          await axios.post("/group/remove/moderator", {
            groupId: group?.id,
            memberId: selectedUser?.id,
          });

          setGroup({
            ...group,
            members: group?.members.map((x) => {
              if (x.id === selectedUser?.id) {
                x.isModerator = false;
              }
              return x;
            }),
            moderators: group?.moderators.filter(
              (x) => x.id !== selectedUser?.id
            ),
          });
          setOpen(false);
          toast.success("Removed Successfully!");
        } catch (error) {}
      },
    },
    {
      id: 6,
      name: "Report",
      img: report,
      hide: userRecord?.id === selectedUser?.id,
      action: () => {
        navigate(REPORT_FORM, {
          state: { id: selectedUser?.id, report: "user" },
        });
      },
    },
  ];

  useEffect(() => {
    QRCode.toDataURL(`${window.location.origin + window.location.pathname}?trigger=join`, {
      errorCorrectionLevel: 'L'
    })
      .then(url => setQrCodeDataUri(url));
  }, [])

  if (loading || !userRecord)
    return <Loader />;

  return (
    <div className="ml-5 mt-3">
      <div className="flex flex-col items-center">
        <QrModalImage className="mx-auto" src={qrCodeDataUri} />
        <span className="text-xs text-center">Click to expand</span>
      </div>

      <Typography color={"text.dark"} className={clsx(" font-bold")}>
        Community
      </Typography>
      <Typography className={clsx(classes.textColor, " font-bold")}>
        {group?.community?.name}
      </Typography>
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Description
      </Typography>
      <Typography className={clsx()}>{group?.description}</Typography>
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Rules
      </Typography>
      <Typography className={clsx()}>{group?.rules}</Typography>

      {/* <Typography color={"text.dark"} className={clsx("mt-5 mb-2 font-bold")}>
        Resources
      </Typography>
      <ResourcesPreview onClick={Resources} resources={mediaResourcePreview} /> */}
      {/* <img
        onClick={Resources}
        className="mb-3 cursor-pointer"
        src={resources}
        alt="Resources"
      /> */}
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Admin / Owner
      </Typography>
      <MyAvatar
        className="z-0 w-14 h-14 mr-3"
        src={group?.admin?.media?.avatarUrl}
        textProps={{ className: "font-bold" }}
        name={group?.admin?.userName}
        subtext={group?.admin?.firstName + " " + group?.admin?.lastName}
      />

      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Members
      </Typography>
      <div className="mb-5">
        {members.length === 0 && <Typography>No members yet</Typography>}
        {members?.map((member) => (
          <div
            key={member?.id}
            className="flex mt-2 items-center mr-5 justify-between"
          >
            <div className="flex">
              <MyAvatar
                textProps={{ className: "font-bold" }}
                name={member?.userName}
                className="z-0 w-14 h-14 mr-3"
                subtext={member?.firstName + " " + member?.lastName}
                src={member?.media?.avatarUrl}
              />
              {member.isModerator && (
                <div className="flex flex-col justify-center">
                  <div className="flex items-center">
                    <Typography
                      className={clsx(
                        "ml-4 p-2 rounded-lg",
                        classes.colorGreen
                      )}
                      color={"primary.main"}
                    >
                      Moderator
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            {userRecord.id !== member.id && (
              <MoreHorizIcon
                onClick={() => {
                  setSelectedUser(member);
                  setOpen(true);
                }}
                className=" cursor-pointer"
                fontSize="large"
              />
            )}
          </div>
        ))}
        {page < totalPages && (
          <div
            onClick={() => incPage()}
            className="cursor-pointer my-2 text-center mx-4"
          >
            <ShowMore>Show More</ShowMore>
          </div>
        )}
      </div>
      <GroupModal open={open} setOpen={setOpen} options={options} />
    </div>
  );
}

export default GroupDescription;
