import React, { useEffect, useMemo, useState } from "react";
import Title from "../../components/Title";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import TabPane from "@components/TabPane";
import ProfilePreferredTags from "./ProfilePreferredTags";
import ProfileRestrictedTags from "./ProfileRestrictedTags";

function ProfileTagManagement() {
  useLoggedIn();

  const tabs = ['Preferred Content', 'Restricted Content']

  const [activeTab, setActiveTab] = useState(0)

  const onTabChange = (index) => {
    if (index >= 0 && index < tabs.length)
      setActiveTab(index);
  };

  return (
    <Layout>
      <Title back="/">Profile Tag Management</Title>
      <TabPane
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={onTabChange}
      >
        <ProfilePreferredTags />
        <ProfileRestrictedTags />
      </TabPane>
    </Layout>
  )
}

export default ProfileTagManagement;