import React from "react";
import { Close } from "@mui/icons-material";
import clsx from "clsx";

export default function ProfileTag({
  name,
  onClick,
  onRemove,
  disabled = false,
  selected = false,
  highlightColor = 'rgb(199, 245, 228)',
  removalMuiColor,
  readOnly = false,
}) {
  const handleTagClick = (e) => {
    if(!disabled)
      onClick();
  }

  const handleCloseClick = (e) => {
    if(!disabled) {
      e?.stopPropagation?.();
      onRemove();
    }
  }

  return (
    <div
      className={clsx("px-2 py-1 rounded-md mr-2 flex gap-4", readOnly ? '' : 'cursor-pointer')}
      onClick={readOnly ? null : handleTagClick}
      style={{
        backgroundColor: selected ? highlightColor : "rgba(98, 98, 98, 0.1)",
        opacity: disabled ? 0.5 : 1
      }}
    >
      <div>{name}</div>
      {onRemove && (
        <div onClick={handleCloseClick} className="cursor-pointer">
          <Close sx={{ fontSize: "20px" }} color={removalMuiColor ?? "primary"} />
        </div>
      )}
    </div>
  );
}
