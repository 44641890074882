import React, { useEffect, useMemo, useState } from "react";
import Title from "../../components/Title";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import TabPane from "@components/TabPane";
import GroupPreferredTags from "./GroupPreferredTags";
import GroupRestrictedTags from "./GroupRestrictedTags";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import GroupPublicTagsPage from "./GroupPublicTagsPage";

const useStyles = makeStyles(theme => ({
  tabHeight: {
    minHeight: '30px !important',
    height: '30px !important',
  },
  tabContainer: {
    width: '75% !important',
    marginTop: 12,
    border: 'none',
  }
}))

function GroupTagManagement({ group, getGroup }) {
  useLoggedIn();

  const classes = useStyles();

  const { userRecord } = useSelector((state) => state.user);

  const tabs = ['Preferred Content', 'Restricted Content']

  const [activeTab, setActiveTab] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onTabChange = (index) => {
    if (index >= 0 && index < tabs.length)
      setActiveTab(index);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, [])

  const userIsAdmin = userRecord?.id === group?.admin?.id;

  if(!userIsAdmin)
    return <GroupPublicTagsPage group={group} />

  return (
    <TabPane
      unfixed
      centered={windowWidth >= 640}
      tabs={tabs}
      activeTab={activeTab}
      onTabChange={onTabChange}
      tabsClass={classes.tabHeight}
      tabContainer={classes.tabContainer}
      tabBoxClasses='relative z-0 border-none'
    >
      <GroupPreferredTags group={group} getGroup={getGroup} />
      <GroupRestrictedTags group={group} getGroup={getGroup} />
    </TabPane>
  )
}

export default GroupTagManagement;