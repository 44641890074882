import { GET_CONNECTIONS, REMOVE_CONNECTION } from "../constants/api";
import { Button, Typography } from "@mui/material";
import axios from "@MyAxios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MyAvatar from "./admin/MyAvatar";
import { useNavigate } from "react-router-dom";
import { refreshUserRecord } from "@helpers";
import { useDispatch } from "react-redux";

function UserCard({ user, setUsers, isPending }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const removeConnection = (id) => {
    axios
      .post(REMOVE_CONNECTION, { id })
      .then((res) => {
        setUsers((users) => users.filter((user) => user.id !== id));
        toast.success("Connection removed successfully");
        refreshUserRecord(dispatch);
      })
      .catch(() => {});
  };

  return (
    <div
      onClick={() => navigate(`/${user.userName}`)}
      key={user?.id}
      className=" flex justify-between gap-2 p-2 border-b-2 border-gray-100 cursor-pointer"
    >
      <MyAvatar
        subtext={user?.firstName + " " + user?.lastName}
        src={user?.media?.avatarUrl}
        name={user.userName}
      />
      {isPending ? (
        <Typography color="primary" className="text-sm py-3 px-2">
          Pending
        </Typography>
      ) : (
        <Button
          color="error"
          className="text-sm"
          sx={{
            textTransform: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            removeConnection(user.id);
          }}
        >
          Remove
        </Button>
      )}
    </div>
  );
}

function MyConnections() {
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  useEffect(() => {
    axios
      .get(GET_CONNECTIONS)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {});
    axios
      .get("/connections/pending")
      .then((res) => {
        setPendingUsers(res.data);
        console.log(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <div className=" flex flex-col p-2">
      {users?.map((user) => (
        <UserCard user={user} setUsers={setUsers} />
      ))}
      {pendingUsers?.map((user) => (
        <UserCard user={user} setUsers={setPendingUsers} isPending />
      ))}
    </div>
  );
}

export default MyConnections;
