import React from 'react'
import { Typography } from '@mui/material';
import ProfileTag from './ProfileTag';

function GroupPublicTagsPage({ group }) {
  return (
    <div className='px-4 pt-2'>
      <Typography color="text.dark" className="font-bold mb-2">
        Preferred Content
      </Typography>

      <div className="flex gap-2 flex-wrap mb-4">
        {!group?.groupTags?.length && (
          <Typography>
            No preferred tags selected
          </Typography>
        )}
        {group?.groupTags?.map((tag) => (
          <ProfileTag
            readOnly
            selected
            name={tag}
          />
        ))}
      </div>

      <Typography color="text.dark" className="font-bold mb-2">
        Restricted Content
      </Typography>

      <div className="flex gap-2 flex-wrap mb-4">
        {!group?.restrictedTags?.length && (
          <Typography>
            No restricted tags selected
          </Typography>
        )}
        {group?.restrictedTags?.map((tag) => (
          <ProfileTag
            readOnly
            selected
            name={tag}
            highlightColor='#ffc1c1'
          />
        ))}
      </div>
    </div>
  )
}

export default GroupPublicTagsPage;