import React, { useEffect, useState } from 'react'
import Title from '@components/Title';
import Layout from '@Layout';
import { toast } from 'react-toastify';
import { Autocomplete, Button, Chip, TextField } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import validator from 'validator';
import axios from '@MyAxios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function GroupInviteExternal() {
  const location = useLocation();
  const navigate = useNavigate();

  const { groupId } = useParams();

  const [tempEmail, setTempEmail] = useState('');
  const [inviteEmails, setInviteEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddEmail = (emails, reason, newEmail = tempEmail) => {
    if(emails?.length > 10)
      return toast.error('You cannot invite more than 10 emails at a time');

    if(reason = 'createOption') {
      let trimmedEmail = newEmail.trim();

      if(!trimmedEmail)
        return toast.error('Please enter an email');

      if(!validator.isEmail(trimmedEmail)) {
        setTempEmail(trimmedEmail);
        return toast.error('Email format is invalid');
      }
  
      setInviteEmails(emails);
    }
  }

  const handleRemoveEmail = (email) => {
    setInviteEmails(inviteEmails.filter(e => e !== email));
  }

  const handleSubmit = () => {
    if(inviteEmails.length === 0)
      return toast.error('No emails entered to invite');

    setLoading(true);
    axios.get('/invite-external-to-group', {
      params: {
        groupId,
        userEmails: inviteEmails
      }
    })
      .then(res => {
        toast.success(res.data?.message);
        setInviteEmails([]);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if(!location.state?.groupName || !groupId)
      navigate(-1);
  })

  return (
    <Layout>
      <Title>Group Email Invites</Title>

      <div className='p-4 mt-4'>
        <Autocomplete
          freeSolo
          multiple
          options={[]}
          clearIcon={false}
          value={inviteEmails}
          inputValue={tempEmail}
          onInputChange={(_, value) => setTempEmail(value)}
          // onInputChange={(e, value, reason) => handleAddEmail(val)}
          onChange={(_, values, reason, details) => handleAddEmail(values, reason, details?.option)}
          props
          renderTags={(value, props) =>
            value.map((option, index) => (
              <Chip
                {...props({ index })}
                label={option}
                onDelete={() => handleRemoveEmail(option)}
              />
            ))
          }
          renderInput={(params) =>
            <TextField
              {...params}
              label="Enter emails to invite"
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment:
              //     <SendIcon
              //       className='cursor-pointer'
              //     />
              // }}
            />
          }
        />

        <div className='flex justify-end mt-4'>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={loading}
          >
            Invite to {location.state?.groupName}
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default GroupInviteExternal;