import React, { useEffect, useState } from 'react'
import Loader from '@components/Loader';
import Post from '@components/Post';
import axios from '@MyAxios';
import { useDispatch, useSelector } from 'react-redux';
import { refreshUserRecord } from '@helpers';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import ShowMore from '@components/ShowMore';

function OtherUserMutualPosts({ user }) {
  const dispatch = useDispatch();

  const { userRecord } = useSelector((state) => state.user);
  const { clickedPostId } = useSelector((state) => state.prevPosts);

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchMutualPosts = async () => {
    try {
      setLoading(true);
      await axios.get(`/posts/mutual/${user.id}`, {
        params: {
          page,
          limit: 15,
        }
      }).then((res) => {
        setPosts([ ...posts, ...res.data.posts ]);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleIncrementPage = () => {
    setPage(page + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      let postElement;
      if (clickedPostId) {
        postElement = document.getElementById(clickedPostId);
      }
      if (postElement) {
        postElement.scrollIntoView({ behavior: "smooth" });
        clearInterval(interval);
      }
    }, 100);
  }, [clickedPostId]);

  useEffect(() => {
    fetchMutualPosts();
  }, [page])

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {posts.length === 0 && (
            <Typography className='px-5 pt-5 border-t-2'>
              No mutual posts...
            </Typography>
          )}
          {posts.map((post, index) => (
            <div
              key={post.id}
              id={
                post.groups?.[0]
                ? `group-${post.groups?.[0]?.id}-${post?.id}`
                : `post-${post.id}`}
              sx={{
                borderColor: (theme) => theme.palette.primary.main,
                scrollMarginTop: 110
              }}
              className={clsx("border-b-2", index === 0 ? 'border-t-2' : '')}
            >
              <Post
                noHeader
                comment
                post={post}
                group={post.groups[0]}
                savedItems={userRecord.savedItems}
                setGetSaved={() => refreshUserRecord(dispatch)}
              />
            </div>
          ))}
          {page < totalPages && (
            <div
              onClick={handleIncrementPage}
              className="cursor-pointer my-2 text-center mx-4"
            >
              <ShowMore>Show More</ShowMore>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default OtherUserMutualPosts;